import { Navigate, useParams } from 'react-router';

import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { CmsPagePageLoadable } from 'modules/cms/pages/Page/CmsPagePageLoadable';
import { CmsPostPageLoadable } from 'modules/cms/pages/Post/CmsPostPageLoadable';
import { useCmsPageRevalidateAfterUserAuthentication } from 'modules/cms/routing/cmsRouteRevalidation';
import { postSearchRouteReference } from 'modules/postSearch/routing/postSearch.routes';
import { searchGetRoutePathSwitch } from 'modules/search/routing/helpers/searchGetRoutePathSwitch';

type Props = {
  content: CmsApiPage;
};

export function CmsContentPage({ content }: Props) {
  const hasHomePage = content.subsite && Boolean(content.subsite.homePage);
  const { heroSearch } = content;

  const onlySearchResults = heroSearch?.onlyResults;
  const onlyArticleResults = content.onlySearchResults && hasHomePage;

  const { q, pq } = useParams();

  useCmsPageRevalidateAfterUserAuthentication();

  if (onlySearchResults) {
    return (
      <Navigate
        to={searchGetRoutePathSwitch({
          q: typeof q === 'string' ? q : undefined,
          lang: CURRENT_LOCALE(),
          filtersByName: heroSearch.defaultSearchFilters,
        })}
        replace
      />
    );
  }

  if (onlyArticleResults) {
    return (
      <Navigate
        to={postSearchRouteReference.with({
          pq: typeof pq === 'string' ? pq : '',
          slug: content.url,
        })}
        replace
      />
    );
  }

  return content.type === 'PAGE' ? (
    <CmsPagePageLoadable content={content} />
  ) : (
    <CmsPostPageLoadable content={content} />
  );
}
